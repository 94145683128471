<template>
  <div>
    <b-overlay
      :show="isFetching"
      spinner-variant="primary"
    >
      <b-card>
        <b-row class="d-flex">
          <b-col>
            <validation-provider
              #default="{ errors, failedRules }"
              name="zone_name"
              rules="required"
            >
              <b-form-input
                ref="name"
                v-model="name"
                :placeholder="$t('Zone Name')"
                class="input-h input-h4"
                :state="errors.length ? false : null"
                @blur="onBlurName(errors)"
              />
              <small class="text-danger">
                <template v-if="failedRules.required">{{ $t('validationErrorRequiredZoneName') }}</template>
                <template v-else>{{ errors[0] }}</template>
              </small>
            </validation-provider>
          </b-col>
          <b-col
            cols="auto"
            style="padding-top: 9px"
          >
            <div class="d-flex">
              <feather-icon
                v-b-tooltip.hover.top="$t('zoneTooltipDownload')"
                class="mr-2"
                icon="DownloadIcon"
                role="button"
                size="20"
                @click="isModalQrPrint = true"
              />
              <b-spinner
                v-if="isPreviewLoading"
                variant="primary"
                class="spinner-20 mr-2"
              />
              <icon-preview
                v-else
                v-b-tooltip.hover.top="$t('Preview')"
                role="button"
                class="size-20 mr-2"
                @click.native="openPreviewModal"
              />
              <b-spinner
                v-if="isDuplicating"
                variant="primary"
                class="spinner-20 mr-2"
              />
              <feather-icon
                v-if="isDuplicate"
                v-b-tooltip.hover.top="$t('Duplicate')"
                class="mr-2"
                icon="CopyIcon"
                role="button"
                size="20"
                @click="onClickDuplicate"
              />
              <feather-icon
                v-b-tooltip.hover.top="$t('Delete')"
                class="text-danger"
                icon="TrashIcon"
                size="20"
                role="button"
                @click="isModalConfirmDelete = true"
              />
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
    <b-overlay
      :show="isFetching"
      spinner-variant="primary"
    >
      <b-card>
        <b-card-title>
          {{ $t('Tables') }}
          <feather-icon
            icon="InfoIcon"
            role="button"
            size="20"
            style="vertical-align: top"
            class="ml-25 text-primary toggle-content"
            @click.stop="setIsInfo(['zone', 'tables'])"
          />
        </b-card-title>
        <b-collapse v-model="isInfo.zone.tables">
          <small class="d-block mb-2">
            {{ $t('infoZoneTables') }}
          </small>
        </b-collapse>
        <template v-if="tables.length">
          {{ $t('zoneTablesCanonical') }}: {{ tablesCanonical }}
        </template>
        <template v-else>
          {{ $t('zoneTablesNo') }}
        </template>
        <b-input-group class="mt-2">
          <b-form-input
            v-model="tablesToAdd"
            :placeholder="$t('zoneTablePlaceholder')"
            @keydown.enter="onAddTables"
          />
          <template #append>
            <b-button
              variant="outline-primary"
              @click="onAddTables"
            >
              ⏎
            </b-button>
          </template>
        </b-input-group>
        <div
          v-if="tables.length"
          class="mt-2 mb-n50"
        >
          <b-badge
            v-for="table in [...tables].sort((a, b) => a.name - b.name)"
            :key="table.id"
            variant="light-secondary"
            class="mr-50 mb-50 badge-table"
          >
            {{ table.name }}
            <feather-icon
              icon="XIcon"
              role="button"
              @click="onDeleteTable(table.id)"
            />
          </b-badge>
        </div>
      </b-card>
    </b-overlay>
    <b-overlay
      :show="isFetching"
      spinner-variant="primary"
    >
      <b-card class="mb-0">
        <b-card-title>
          {{ $t('Menus') }}
          <feather-icon
            icon="InfoIcon"
            role="button"
            size="20"
            style="vertical-align: top"
            class="ml-25 text-primary toggle-content"
            @click.stop="setIsInfo(['zone', 'menus'])"
          />
        </b-card-title>
        <b-collapse v-model="isInfo.zone.menus">
          <small class="d-block mb-2">
            {{ $t('infoZoneMenus') }}
          </small>
        </b-collapse>
        <treeselect
          v-model="menus"
          v-b-tooltip.hover.top="$t('placeholderSelectMenu')"
          :multiple="true"
          :disable-branch-nodes="true"
          :clearable="false"
          :placeholder="$t('placeholderSelectMenu')"
          :options="menusList.filter(i => i.isPublished).map(i => ({ ...i, label: i.name }))"
          value-format="object"
          :no-options-text="$t('noOptionsMenus')"
          @close="onCloseMenusTreeselect"
        />
        <b-row
          v-if="menus.length"
          class="mt-1"
        >
          <b-col>
            <b-form-group
              :label="$t('zoneMenuLabel')"
              class="mb-0"
            />
          </b-col>
        </b-row>
        <draggable
          v-model="menus"
          @end="onEndDragMenus"
        >
          <b-row
            v-for="(menu, menuIndex) in menus"
            :key="menu.id"
            :class="[menuIndex !== 0 ? 'pt-1' : null]"
          >
            <b-col>
              <div style="cursor: grabbing">
                <b-link :to="{ name: 'menu', params: { id: menu.id } }">
                  {{ menu.name }}
                </b-link>
              </div>
            </b-col>
            <b-col
              cols="auto"
              class="d-flex"
            >
              <feather-icon
                size="20"
                role="button"
                icon="XCircleIcon"
                @click="onClickDeleteMenu(menu.id)"
              />
            </b-col>
          </b-row>
        </draggable>
      </b-card>
    </b-overlay>
    <modal-preview
      v-model="isModalPreview"
      :link="previewUrl"
    />
    <modal-confirm-delete
      v-model="isModalConfirmDelete"
      :title="`${$t('zoneDeleteTitle')}`"
      :is-message="false"
      @delete="onDelete"
    />
    <modal-qr-print
      v-model="isModalQrPrint"
      :zone-id="$route.params.id"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCol,
  BCollapse,
  BFormInput,
  BLink,
  BOverlay,
  BRow,
  BSpinner,
  BFormGroup,
  BInputGroup,
  BButton,
  BBadge,
  VBToggle,
  VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import Treeselect from '@riophae/vue-treeselect'
import { mapGetters, mapActions } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'
import ModalPreview from '@/components/modal/ModalPreview.vue'
import ModalQrPrint from '@/components/modal/ModalQrPrint.vue'
import IconPreview from '@/components/icon/IconPreview.vue'
import ModalConfirmDelete from '@/components/modal/ModalConfirmDelete.vue'
import mixinTooltipCreateZone from '@/mixins/tooltip/tooltip-create-zone'
import mixinRequests from '@/mixins/requests'

import DELETE_ZONE from '@/gql/mutation/zone/deleteZone.gql'
import DUPLICATE_ZONE from '@/gql/mutation/zone/duplicateZone.gql'

export default {
  name: 'Zone',
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  components: {
    BCard,
    BCardTitle,
    BCol,
    BFormInput,
    BRow,
    BCollapse,
    BLink,
    Treeselect,
    BOverlay,
    BSpinner,
    BFormGroup,
    BInputGroup,
    BButton,
    BBadge,
    draggable,
    ModalConfirmDelete,
    IconPreview,
    ModalPreview,
    ModalQrPrint,

    // validations
    ValidationProvider,
  },
  mixins: [
    mixinTooltipCreateZone,
    mixinRequests,
  ],
  data() {
    return {
      isDuplicating: false,
      isFetching: true,

      name: '',
      tables: [],
      menus: [],

      isModalQrPrint: false,
      isModalConfirmDelete: false,

      isPreviewLoading: false,
      isModalPreview: false,
      previewUrl: '',

      tablesToAdd: '',

      // validation rules
      required,
    }
  },
  computed: {
    ...mapGetters({
      menusList: 'menuManagement/menus',
      restaurant: 'restaurant/restaurant',
      zones: 'floorPlan/zones',
      events: 'floorPlan/events',
      isInfo: 'main/isInfo',
      isMenusFetched: 'menuManagement/isMenusFetched',
      isZonesFetched: 'floorPlan/isZonesFetched',
    }),
    isDuplicate() {
      return !this.isDuplicating && !this.isCreateZoneDisabled
    },
    tablesCanonical() {
      let start
      let end
      const segments = []
      const tables = [...this.tables].sort((a, b) => a.name - b.name)

      tables.forEach(table => {
        const i = parseInt(table.name, 10)
        if (i === end + 1) {
          end = i
        } else {
          if (start) {
            segments.push([start, end])
          }
          start = i
          end = i
        }
      })

      if (start) {
        segments.push([start, end])
      }

      return segments.map(i => (i[1] > i[0] ? `${i[0]}-${i[1]}` : `${i[0]}`)).join(', ')
    },
  },
  watch: {
    isMenusFetched() {
      this.fetch()
    },
    isZonesFetched() {
      this.fetch()
    },
    $route: {
      immediate: true,
      handler() {
        this.fetch()
      },
    },
  },
  methods: {
    ...mapActions({
      setZones: 'floorPlan/setZones',
      setIsInfo: 'main/setIsInfo',
      setNavHistory: 'main/setNavHistory',
      updateZone: 'floorPlan/updateZone',
      updateEvent: 'floorPlan/updateEvent',
      setEvents: 'floorPlan/setEvents',
      getEvents: 'floorPlan/getEvents',
      shortenPmenusRequest: 'main/shortenPmenusRequest',
    }),
    async openPreviewModal() {
      this.isPreviewLoading = true

      const code = await this.shortenPmenusRequest([this.restaurant.id, this.$route.params.id])
      this.previewUrl = `${process.env.VUE_APP_ORIGIN_CLIENT}/${code}`
      // this.previewUrl = `http://localhost:8081/${code}`

      this.isModalPreview = true
      this.isPreviewLoading = false
    },

    patchZoneTables() {
      // eslint-disable-next-line no-undef
      const tables = structuredClone(this.tables)
      this.setZones(this.zones.map(i => (i.id === this.$route.params.id ? { ...i, tables } : i)))

      return [
        this.updateZone([this.$route.params.id, 'tables', this.tables]),
      ]
    },
    onAddTables() {
      const matches = this.tablesToAdd.match(/(\d+)|(-\D*)/g)
      if (matches) {
        for (let i = 0; i < matches.length; i += 1) {
          let start = +matches[i]
          let end = start
          if (start >= 0 && i < matches.length - 2 && matches[i + 1][0] === '-' && +matches[i + 2] >= 0) {
            end = +matches[i + 2]
            i += 2
            if (start > end) {
              [start, end] = [end, start]
            }
          }
          for (let j = start; j <= end; j += 1) {
            const name = j.toString()
            if (this.tables.every(k => k.name !== name)) {
              this.tables = [...this.tables, { id: uuidv4(), name }]
            }
          }
        }
        this.requests.push('patchZoneTables')
      }
      this.tablesToAdd = ''
    },
    onDeleteTable(id) {
      this.tables = this.tables.filter(i => i.id !== id)
      this.requests.push('patchZoneTables')

      const eventsToUpdate = this.events
        .filter(i => i.excludedTables.find(j => j.id === id))
        .map(i => this.updateEvent([i.id, 'excludedTables', i.excludedTables.filter(j => j.id !== id)]))
      Promise.all(eventsToUpdate)
      this.setEvents(this.events.map(i => ({ ...i, excludedTables: i.excludedTables.filter(j => j.id !== id) })))
    },

    patchZoneMenus() {
      // eslint-disable-next-line no-undef
      const menus = structuredClone(this.menus)
      this.setZones(this.zones.map(i => (i.id === this.$route.params.id ? { ...i, menus } : i)))

      return [
        this.updateZone([this.$route.params.id, 'menus', this.menus.map(i => i.id)]),
      ]
    },
    onCloseMenusTreeselect() {
      this.requests.push('patchZoneMenus')
    },
    onClickDeleteMenu(id) {
      this.menus = this.menus.filter(i => i.id !== id)
      this.requests.push('patchZoneMenus')
    },
    async onEndDragMenus(event) {
      if (event.oldIndex === event.newIndex) {
        return
      }
      this.requests.push('patchZoneMenus')
    },

    patchZoneName() {
      this.setZones(this.zones.map(i => (i.id === this.$route.params.id ? { ...i, name: this.name } : i)))

      return [
        this.updateZone([this.$route.params.id, 'name', this.name]),
      ]
    },
    onBlurName(errors) {
      if (this.name === this.zones.find(i => i.id === this.$route.params.id).name) {
        return
      }

      if (errors.length) {
        return
      }

      this.requests.push('patchZoneName')
    },

    async fetch() {
      if (!this.isMenusFetched || !this.isZonesFetched) {
        return
      }

      this.isFetching = true

      const history = ['zone', this.$route.params.id]
      this.setNavHistory(history)

      const zone = this.zones.find(i => i.id === this.$route.params.id)
      if (!zone) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: this.$t('Not found'),
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        await this.$router.push({ name: 'zones' })
        return
      }

      this.name = zone.name

      // eslint-disable-next-line no-undef
      this.tables = structuredClone(zone.tables).sort((a, b) => a.name - b.name)

      // eslint-disable-next-line no-undef
      this.menus = structuredClone(zone.menus)

      if (this.$refs.name && this.$route.params.isFocusOnName) {
        this.$refs.name.focus()
      }

      this.isFetching = false
    },
    async onDelete() {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_ZONE,
          variables: {
            deleteZoneId: this.$route.params.id,
          },
        })
        await Promise.all([this.getEvents()])

        this.setZones(this.zones.filter(i => i.id !== this.$route.params.id))

        await this.$router.push({ name: 'zones' })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Success'),
            text: this.$t('notificationSuccessDeleted'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async onClickDuplicate() {
      this.isDuplicating = true

      try {
        const response = await this.$apollo.mutate({
          mutation: DUPLICATE_ZONE,
          variables: {
            duplicateZoneId: this.$route.params.id,
          },
        })

        // eslint-disable-next-line no-undef
        this.setZones([...this.zones, structuredClone(response.data.duplicateZone)])

        await this.$router.push({
          name: 'zone',
          params: {
            id: response.data.duplicateZone.id,
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isDuplicating = false
      }
    },
  },
}
</script>

<style lang="sass">
  @import '@/assets/sass/treeselect.sass'

  .badge-table
    .feather
      display: none
    &:hover
      .feather
        display: inline
</style>
